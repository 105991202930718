<template>
  <v-card elevation="0" tile>
    <v-card-text>
      <v-form v-model="form">
        <v-text-field
          v-model="credentials.email"
          :label="$t('page.login.email.title')"
          solo
        ></v-text-field>
        <v-text-field
          v-model="credentials.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('page.login.password.title')"
          :hint="$t('page.login.password.hint.passwordLength')"
          counter
          autocomplete
          solo
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn text link tile :to="{ name: toForgotPassword }">{{
        $t("page.login.forgottenPassword")
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        class="px-12"
        large
        color="primary"
        @click="login()"
        :disabled="!hasValidCredentials"
        >{{ $t("page.login.login") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import * as Routes from "../../router/route-names";
export default {
  name: "Login",
  data() {
    return {
      form: null,
      showPassword: false,
      credentials: { email: null, password: null },
      rules: {
        required: (value) =>
          !!value || this.$t("page.login.password.errors.required"),
        min: (v) =>
          (v != null && v.length >= 8) ||
          this.$t("page.login.password.errors.passwordLength"),
      },
    };
  },
  computed: {
    hasValidCredentials() {
      return true;
      //   var cred = this.credentials;
      //   if (cred.email == null || !cred.email.trim().length) return false;
      //   if (cred.password == null || cred.password.trim().length < 8)
      //     return false;
      //   return true;
    },
    toForgotPassword() {
      return Routes.FORGOT_PASSWORD;
    },
  },
  methods: {
    login() {
      //alert(this.$store.state.user);
      this.$store.dispatch("auth/login", this.credentials);
      //this.$store.dispatch("auth/init");
    },
  },
};
</script>